const ErrorLayout = () => {
  return (
    <>
      <div className="bg-rose-600 h-screen w-screen">
        <h1 className="flex flex-row justify-center text-9xl items-center h-full">
          Error 404
        </h1>
      </div>
    </>
  );
};
export default ErrorLayout;
